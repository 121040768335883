import GET_DOMAIN from '@/graphql/GetDomain.gql';

export default {
  data() {
    return {
      domain: {
        domain: '',
        lastRequestDate: null,
        analytics: {
          enabled: false,
        },
      },
    };
  },

  computed: {
    isAnalyticsEnabled() {
      return this.domain.analytics.enabled;
    },
  },

  methods: {
    async fetchDomainInfos() {
      const {
        data: { domain },
      } = await this.$apollo.query({
        query: GET_DOMAIN,
        variables: {
          domainId: this.campaign.domainId,
        },
      });

      if (domain) {
        this.domain = domain;
      }
    },
  },

  watch: {
    campaign: {
      handler() {
        if (this.campaign.domainId) {
          this.fetchDomainInfos();
        }
      },
      deep: true,
    },
  },
};
