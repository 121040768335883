<template lang="pug">
om-modal.campaign-alerts-modal(
  name="campaign-alerts-modal"
  @beforeOpen="beforeOpen"
  width="810"
  color="light"
)
  template(slot="modal-header")
    .w-100.text-left.font-weight-bold {{ $t('campaignAlerts.modal.title') }} ({{ alertsCount }})
    .brand-modal-action-icon
      span.cursor-pointer(@click="$modal.hide('campaign-alerts-modal')")
        close-icon(:width="14" :height="14" :color="'#C2C2C2'")
  template(slot="modal-body")
    .table-responsive
      table.table.table-fixed
        tbody
          template(v-for="(item, index) in alerts")
            .alert-accordion.d-flex.flex-column
              tr
                td.col-6.alert-col.col-with-padding(
                  @click="toggleHowTo(index)"
                  :class="{ 'table-toggle-btn-open': activeSlide === index, 'cursor-pointer': multipleAlerts }"
                )
                  uil-angle-right.table-toggle-btn(:size="'16px'" v-if="multipleAlerts")
                  uil-exclamation-triangle(
                    v-if="getAlertType(item.type) === 'error'"
                    :size="'24px'"
                    :color="errorColor"
                  )
                  uil-exclamation-circle(v-else :size="'24px'" :color="warningColor")
                  span.alert-name.font-size-0--875 {{ getAlertTitle(item) }}
                td.col-3.alert-date.text-right {{ lastTriggeredDate(item.triggeredAt) }}
                td.alert-date-col.col-2.d-flex.justify-content-end.px-0
                  om-button.fix-btn(
                    secondary
                    @click="triggerAlertFix(item)"
                    v-if="!isComputedAlert(item) && !isNonFixableIntegrationError(item.type)"
                  ) {{ $t('campaignAlerts.modal.fix') }}
                td.alert-close-col.col-1.d-flex.justify-content-end.pl-2
                  close-icon.cursor-pointer(
                    @click="dismissAlert(item)"
                    :width="12"
                    :height="12"
                    color="#505763"
                    v-if="!isComputedAlert(item)"
                  )

              tr.table-toggle-row(:class="{ active: activeSlide === index }")
                td.col-12.col-with-padding.pr-4(colspan="4")
                  small.alert-description.mb-2(
                    v-if="needDesc(item.type)"
                    v-html="$t(`campaignAlerts.${getAlertTypeWithV3(item.type)}.description`, descriptionOptions(item))"
                  )
                  small.alert-below(
                    v-if="$te(`campaignAlerts.${getAlertTypeWithV3(item.type)}.findBelow`)"
                    v-html="$t(`campaignAlerts.${getAlertTypeWithV3(item.type)}.findBelow`)"
                  )
                  .alert-items.mb-3(v-if="hasListItems(item.type)")
                    .alert-item.font-size-0--75(
                      v-for="row in getListItems(item)"
                      v-html="$t(`campaignAlerts.${item.type}.item`, row)"
                    )
                  alert-how-to-fix(
                    :alert="item"
                    :campaign="campaign"
                    :campaign-id="campaignId"
                    :userId="userId"
                  )
                    template(v-if="isListFixInstructions(item)")
                      slot
                        ul.how-to-install-list
                          li(
                            v-for="index in 3"
                            v-html="$t(`campaignAlerts.${getAlertTypeWithV3(item.type)}.step${index}`, { insertCodePage })"
                          )
  template(slot="modal-footer")
    .learn-more-section.mb-4.p-4
      .row
        .col-10.d-flex.pr-2
          UilGraduationCap.col-auto.px-0(size="1.25rem")
          om-body-text.pl-2.learn-more-section-text(bt400xs)(
            v-html="$t('campaignAlerts.modal.learnMore')"
          )
        .col-2.d-flex.justify-content-end.pl-0
          om-link.read-more(
            withIconLeft
            v-if="supportArticle"
            @click="supportArticleClick"
            secondary
          )
            span {{ $t('readMore') }}
            template(slot="left-icon")
              UilBookAlt.mr-2(size="1.5rem")
</template>
<script>
  import {
    UilExclamationTriangle,
    UilExclamationCircle,
    UilAngleRight,
    UilGraduationCap,
    UilBookAlt,
    UilPlayCircle,
  } from '@iconscout/vue-unicons';
  import moment from 'moment';
  import { campaignAlertByTypes, computedCampaignAlertTypes } from '@/utils/campaignAlerts';
  import AlertHowToFix from '@/components/Campaign/AlertHowToFix';
  import dateFormat from '@/mixins/dateFormat';
  import userAlert from '@/mixins/userAlert';
  import userAlertFixes from '@/mixins/userAlertFixes';
  import LogRocket from 'logrocket';

  import { getAccountIdFromCookie } from '@/util';

  export default {
    components: {
      UilGraduationCap,
      UilBookAlt,
      UilPlayCircle,
      UilExclamationTriangle,
      UilExclamationCircle,
      UilAngleRight,
      AlertHowToFix,
    },
    mixins: [dateFormat, userAlert, userAlertFixes],
    props: {
      alerts: {
        type: [Array, Object],
        default: () => [],
      },
      campaign: {
        type: Object,
        required: true,
      },
      variants: {
        type: [Array, Object],
        default: () => [],
        required: true,
      },
    },
    data() {
      return {
        activeSlide: null,
        campaignAlertByTypes,
        errorColor: '#E4252D',
        warningColor: '#F0C920',
      };
    },
    computed: {
      alertsCount() {
        return this.alerts.length;
      },
      supportArticle() {
        return this.$t(`campaignAlerts.modal.supportArticle`);
      },
      multipleAlerts() {
        return this.alertsCount > 1;
      },
      campaignId() {
        return +this.$route.params.id;
      },
      userId() {
        return +getAccountIdFromCookie();
      },
    },
    watch: {
      multipleAlerts() {
        if (!this.multipleAlerts) {
          this.activeSlide = 0;
        }
      },
    },
    mounted() {
      const openModal = !!this.$route.query.openAlert;

      if (openModal) {
        this.$modal.show('campaign-alerts-modal', { activeSlide: 0 });
      }
    },
    methods: {
      beforeOpen({ params }) {
        this.activeSlide = params.activeSlide ?? null;
      },
      async triggerAlertFix(alert) {
        const fix = this.getPrimaryFix({
          alert,
          userId: this.userId,
          campaignId: this.campaignId,
          campaign: this.campaign,
        });

        if (!this.isNamedIntegrationError(alert.type)) {
          this.dismissAlert(alert);
        }

        if (this.isNamedIntegrationError(alert.type)) {
          this.logRocketTrack('userAlert-fix-click')
        }

        if (fix?.action) {
          fix.action(...fix.params);
        } else if (typeof fix === 'string') {
          window.open(fix, '_blank');
        }
      },
      toggleHowTo(index) {
        if (this.multipleAlerts) {
          this.activeSlide = this.activeSlide === index ? null : index;
        }
      },
      isComputedAlert(alert) {
        return computedCampaignAlertTypes.includes(alert.type);
      },
      getAlertType(type) {
        return campaignAlertByTypes.error.includes(type) ? 'error' : 'warning';
      },
      lastTriggeredDate(date) {
        return moment(date).format(this.longDateTimeFormat);
      },
      isListFixInstructions(item) {
        return (
          item.type.startsWith('InsertCodeV3Missing') && !this.isShopifyDomain(this.campaign.domain)
        );
      },
      supportArticleClick() {
        return window.open(this.supportArticle, '_blank');
      },
      logRocketTrack(name) {
        const logRocketInited = window._logRocketInited;
        if (logRocketInited) {
          LogRocket.track(name)
        }
      }
    },
  };
</script>
<style lang="sass">
  @import '@/sass/variables/_colors.sass'
  .table thead tr
    position: relative
  .table-toggle-btn
    cursor: pointer
    position: absolute
    top: 0.85rem
    left: 0.4rem
    transition: 300ms
    .table-toggle-btn-open &
      transform: rotate(90deg)
  .table-toggle-row
    visibility: hidden
    opacity: 0
    transition: visibility 0s, opacity 0.5s linear
    position: absolute
    top: -1000px
    &.active
      visibility: visible
      opacity: 1
      position: static
  .table-fixed tbody
    height: 100%
    width: 100%

  .table-fixed thead,
  .table-fixed tbody,
  .table-fixed tr,
  .table-fixed td,
  .table-fixed th
    display: block

  .table-fixed td
    padding: .5rem 0.75rem
    border-top: 0
    font-size: 1rem
    color: $om-gray-700

  .table thead th
    border-top: 0
    border-bottom-width: 1px
    color: $om-gray-700
    font-size: 0.85rem

  .table-fixed tbody td,
  .table-fixed tbody th,
  .table-fixed thead > tr > th
    float: left
    position: relative

    &::after
      content: ''
      clear: both
      display: block

  .campaign-alerts-modal
    .table-responsive
      overflow: visible !important
    .alert-date
      font-size: 14px
    .fix-btn
      width: 72px
      min-width: 72px
      min-height: 32px
      height: 32px
    .learn-more-section
      color: $om-gray-700
      background: $om-gray-100
      border-radius: 4px
    .alert-accordion
      border: 1px solid $om-gray-300
      border-radius: 8px
      margin-bottom: 8px
      tr
        display: flex
        align-items: center
    .brand-modal-footer-content
      margin-top: 2rem
    small
      font-size: 85%
      display: block

    .alert-description
      color: $om-gray-700
      font-size: 12px
    .alert-how-to-fix
      color: $om-gray-700
    .brand-modal .brand-modal-header
      padding: 1.1rem 2.85rem
      font-size: 1.3rem
      border-bottom: 1px solid $om-gray-300

    .brand-modal .brand-modal-body
      padding: 0.875rem 2.85rem 0 2.85rem

    .alert-name
      font-weight: 500
      color: $om-gray-800
      margin-left: 00.85rem
      line-height: 24px

    .alert-col
      -webkit-box-flex: 0
      -ms-flex: 0 0 55%
      flex: 0 0 58%
      max-width: 58%
      display: flex
      align-content: center
    .alert-date-col
      flex: 11.66667%
      max-width: 11.66667%
    .alert-close-col
      flex: 5.3333333333%
      max-width: 5.3333333333%

    .col-with-padding
      padding-left: 2rem

  .how-to-install-list
    font-size: 85%
</style>
